"use client";

/* eslint-disable @typescript-eslint/no-empty-function */

import { datadogLogs } from "@datadog/browser-logs";
import { useAuthStore } from "@kaplan-labs/up-auth-api-client";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import { useOwner } from "@up/data";

import { checkoutReducer } from "./checkout-machine";
import { network } from "./network";
import { CheckoutState } from "./types";

import type { Cart as CartType, LineItem } from "@up/data";
import type { NextRouter } from "next/router";
import type { Dispatch, ReactNode, SetStateAction } from "react";

export type CartContextType = {
  state?: CheckoutState;
  lineItems?: LineItem[];
  isAddingItem: boolean;
  isPolling: boolean;
  error?: Error;
  cartIsVisible: boolean;
  hasBlockingErrors: boolean;

  submit: () => void;
  idle: () => void;
  poll: () => Promise<unknown>;
  addItem: (newItemID: string) => Promise<unknown>;
  removeItem: (itemID: string) => Promise<unknown>;
  setCartIsVisible: Dispatch<SetStateAction<boolean>>;
  applyCoupon: (code: string) => Promise<void>;
  removeCoupon: (code: string) => Promise<unknown>;
  finalValidate: (successCallback: () => void) => Promise<void>;
  handleSubmitAsFree: () => Promise<string | Error>;

  didError: boolean;
};

export const CartContext = createContext<CartContextType>({
  lineItems: [],
  isPolling: false,
  isAddingItem: false,
  error: undefined,
  cartIsVisible: false,
  hasBlockingErrors: false,

  submit: () => {},
  idle: () => {},
  poll: async () => {},
  addItem: async (newItemID: string) => {},
  removeItem: async (itemID: string) => {},
  setCartIsVisible: () => {},
  applyCoupon: async (code: string) => {},
  removeCoupon: async (code: string) => {},
  finalValidate: async () => {},
  handleSubmitAsFree: async () => "",

  didError: false,
});

export const CartProvider = ({
  baseURL,
  children,
  router,
}: {
  baseURL: string;
  children: ReactNode;
  router: NextRouter;
}) => {
  const auth = useAuthStore();
  const owner = useOwner();

  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const [isPolling, setIsPolling] = useState(false);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [error, setError] = useState<Error>();
  const [cartIsVisible, setCartIsVisible] = useState(false);

  const [state, dispatch] = useReducer(checkoutReducer, {
    cart: {
      status: "unstarted",
      isPolling: false,
      isSubmitting: false,
      isApplyingCoupon: false,
      isValidating: false,
      readyForSubmit: false,
    },
  });

  network.setNetworkConfig({
    baseURL,
  });

  /*
    Actions
    ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
  */

  const submit = function () {
    dispatch({ type: "CART_SUBMIT" });
  };

  const idle = function () {
    dispatch({ type: "CART_IDLE" });
  };

  const _persistLineItem = function (newItemID: string) {
    return network.addLineItem(newItemID);
  };

  const addItem = async (newItemID: string) => {
    try {
      setIsAddingItem(true);
      const response = await _persistLineItem(newItemID);
      return Promise.all([response, poll()]).then(() => {
        setIsAddingItem(false);
      });
    } catch (err) {
      // TODO
      datadogLogs.logger.error(
        "CART_ADD_ITEM_DID_ERROR",
        {
          url: window.location.href,
        },
        err as Error,
      );
      return Promise.reject(err as Error);
    }
  };

  const removeItem = async (idToRemove: string) => {
    try {
      const response = await network.removeLineItem(idToRemove);
      return Promise.all([response, poll()]);
    } catch (err) {
      // TODO
      datadogLogs.logger.error(
        "CART_REMOVE_ITEM_DID_ERROR",
        {
          url: window.location.href,
        },
        err as Error,
      );
      return Promise.reject(err as Error);
    }
  };

  const poll = useCallback(
    async function (callback?: () => void) {
      return new Promise<CartType>(function (resolve, reject) {
        const _poll = async function () {
          try {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const email = owner!.email as string;
            const cart = await network.fetchCart(email);
            if (cart.status !== "processing") {
              setIsPolling(false);
              setLineItems(cart.lineItems ?? []);
              dispatch({ type: "CART_DID_LOAD", payload: cart });
              if (callback) {
                callback();
              }
              resolve(cart);
            } else {
              setTimeout(_poll, 350);
            }
          } catch (err) {
            dispatch({ type: "CART_LOADING_DID_ERROR", payload: err as Error });
            datadogLogs.logger.error(
              "CART_LOADING_DID_ERROR",
              {
                url: window.location.href,
              },
              err as Error,
            );
            setIsPolling(false);
            reject(err as Error);
          }
        };

        if (owner) {
          setIsPolling(true);
          dispatch({ type: "CART_POLL" });
          _poll();
        }
      });
    },
    [owner],
  );

  const finalValidate = useCallback(
    async function (successCallback: () => void) {
      if (!state.cart.data) {
        return;
      }

      try {
        dispatch({ type: "CART_FINAL_VALIDATE" });
        await network.finalValidate(state.cart.data);
        const pollResult = await poll();
        const hasBlockingErrors = Boolean(
          pollResult?.errors?.some((error) => error.level > 0),
        );
        if (!hasBlockingErrors) {
          successCallback();
        }
      } catch (err) {
        datadogLogs.logger.error(
          "CART_CART_FINAL_VALIDATE_DID_ERROR",
          {
            url: window.location.href,
          },
          err as Error,
        );
        // TODO: handle error
      }

      dispatch({ type: "CART_FINAL_VALIDATE_COMPLETE" });
    },
    [state, poll],
  );

  const applyCoupon = useCallback(
    async function (code: string) {
      try {
        dispatch({ type: "CART_APPLYING_COUPON" });
        await network.addCoupon(code);
      } catch (err) {
        datadogLogs.logger.error(
          "CART_APPLYING_COUPON_DID_ERROR",
          {
            url: window.location.href,
          },
          err as Error,
        );
        // TODO: handle error
      }

      poll();
      dispatch({ type: "CART_APPLYING_COUPON_COMPLETE" });
    },
    [poll],
  );

  const removeCoupon = useCallback(
    async function (code: string) {
      const response = await network.removeCoupon(code);
      return Promise.all([response, poll()]);
    },
    [poll],
  );

  const handleSubmitAsFree = async function () {
    const cart = state.cart.data as CartType;
    dispatch({ type: "CART_SUBMIT" });

    try {
      await network.submitAsComplete();
      router.push(`/orders/${cart._id}`);
      setTimeout(() => {
        poll();
      }, 2000);
      return Promise.resolve(cart._id);
    } catch (err) {
      dispatch({ type: "CART_SUBMIT_AS_COMPLETE_DID_ERROR" });
      datadogLogs.logger.error(
        "CART_SUBMIT_AS_COMPLETE_DID_ERROR",
        {
          url: window.location.href,
        },
        err as Error,
      );
      return Promise.reject(err as Error);
    }
  };

  /*
    Effects
    ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
  */

  const reducerStarted = useRef(false);

  const _authenticatedInitialFetch = useCallback(async () => {
    if (!owner) return;
    dispatch({ type: "CART_LOADING" });
    setIsPolling(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const email = owner?.email as string;
    try {
      const response = await network.fetchCart(email);
      dispatch({ type: "CART_DID_LOAD", payload: response });
      setLineItems(response.lineItems ?? []);
      setIsPolling(false);
      if (response.status === "processing") {
        poll();
      }
    } catch (err: unknown) {
      setIsPolling(false);
      dispatch({ type: "CART_LOADING_DID_ERROR", payload: err as Error });
      datadogLogs.logger.error(
        "CART_LOADING_DID_ERROR",
        {
          url: window.location.href,
        },
        err as Error,
      );
      setError(err as Error);
    }
  }, [owner, poll]);

  useEffect(
    function authenticatedInitialFetch() {
      if (!auth.currentUser) {
        return;
      }

      if (reducerStarted.current) return;

      reducerStarted.current = true;
      _authenticatedInitialFetch();
    },
    [auth, _authenticatedInitialFetch],
  );

  useEffect(() => {
    reducerStarted.current = false;
  }, [owner, auth]);

  /*
    A user can be in one of two states: authentiated and unauthenticated.
    The goal is to have a single cart context for both states.
  */

  let cartContext: CartContextType = {
    isAddingItem,
    isPolling,
    error,
    lineItems,
    cartIsVisible,
    hasBlockingErrors: Boolean(
      state.cart.data?.errors?.some((error) => error.level > 0),
    ),

    submit,
    idle,
    poll,
    addItem,
    removeItem,
    setCartIsVisible,
    applyCoupon,
    removeCoupon,
    finalValidate,
    handleSubmitAsFree,

    didError: Boolean(error || state?.cart?.error),
  };

  if (auth.currentUser) {
    cartContext = {
      ...cartContext,
      lineItems: state.cart.data?.lineItems ?? [],
      state: state,
    };
  }

  return (
    <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
